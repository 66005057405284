import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from "@/utils/cookie";
import VueI18n from 'vue-i18n'
import EN from "@/locale/en.json";
import RU from "@/locale/ru.json";

Vue.use(VueRouter).use(VueI18n)

const i18n = new VueI18n({
  locale: getCookie('locale'),
  silentTranslationWarn: true,
  messages: {
    en: EN,
    ru: RU
  }
})

const routes = [
  // {
  //   path: '/',
  //   redirect: '',
  // },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: { title: i18n.t('home-page') }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/Auth.vue'),
    meta: { title: i18n.t('registration') }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: { title: i18n.t('Login') }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    meta: { title: i18n.t('profile') },
    children: [
      {
        path: '/profile/',
        name: 'Profile',
        component: () => import('@/views/profile/ProfileMain'),
        meta: { requiresAuth: true, title: i18n.t('profile') }
      },
      {
        path: '/profile/edit-profile',
        name: 'Edit-profile',
        component: () => import('@/views/profile/EditProfile/index.vue'),
        meta: { requiresAuth: true, title: i18n.t('edit-user-profile') }
      },
      {
        path: '/profile/change-password',
        name: 'Change-password',
        component: () => import('@/views/profile/ChangePassword'),
        meta: { requiresAuth: true, title: i18n.t('change-password-2') }
      },
      {
        path: '/profile/documents',
        name: 'Documents',
        component: () => import('@/views/Documents'),
        meta: { requiresAuth: true }
      },
      {
        path: '/profile/appoint-deputy',
        name: 'AppointDeputy',
        component: () => import('@/views/profile/AppointDeputy.vue'),
        meta: { requiresAuth: true, title: i18n.t('assign-deputy')  }
      },
      {
        path: '/profile/confirmation-history',
        name: 'ConfirmationHistory',
        component: () => import('@/views/profile/ConfirmationHistory.vue'),
        meta: { requiresAuth: true, title: i18n.t('confirmation-history-2') }
      }
    ]
  },
  {
    path: '/work-requests',
    name: 'work-requests',
    component: () => import('@/views/workRequests/index'),
    meta: { requiresAuth: true, title: i18n.t('work-requests') }
  },
  {
    path: '/work-requests/:id',
    name: 'work-requests-id',
    component: () => import('@/views/workRequests/WorkRequestsId'),
    meta: { requiresAuth: true, title: i18n.t('view-requests') }
  },
  {
    path: '/members',
    name: 'Members',
    component: () => import('@/views/Members'),
    meta: { requiresAuth: true, title: i18n.t('participants') }
  },
  {
    path: '/activists-rating',
    name: 'ActivistRating',
    component: () => import('@/views/ActivistRating'),
    meta: { title: i18n.t('rating-of-activists')}
  },
  {
    path: '/data-region',
    name: 'DataRegion',
    component: () => import('@/views/DataRegion.vue'),
    meta: { title: i18n.t('regions')}
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('@/views/Contacts'),
    meta: { title: i18n.t('contacts')}
  },
  {
    path: '/tech-support',
    name: 'TechSupport',
    component: () => import('@/views/TechSupport'),
    meta: { title: i18n.t('technical-support-2')}
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About'),
    meta: { title: i18n.t('about-app') },
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('@/views/Partners'),
    meta: { title: i18n.t('partners') },
  },
  {
    path: '/users-management',
    name: 'UsersManagement',
    component: () => import('@/views/UsersManagement'),
    meta: { requiresAuth: true, title: i18n.t('manage-users') },
    children: [
      {
        path: '/admin/users-management/',
        name: 'UsersManagement',
        component: () => import('@/views/usersManagement/UsersManagementMain'),
        meta: { title: i18n.t('manage-users') },
      },
      {
        path: '/admin/users-management/add-new-user',
        name: 'AddNewUser',
        component: () => import('@/views/usersManagement/AddNewUser'),
        meta: { requiresAuth: true, title: i18n.t('add-new-user') },
      },
      {
        path: '/admin/users-management/deleted-users',
        name: 'DeletedUsers',
        component: () => import('@/views/usersManagement/DeletedUsers'),
        meta: { requiresAuth: true, title: i18n.t('deleted-users') },
      },
    ]
  },
  {
    path: '/admin/region',
    name: 'Region',
    component: () => import('@/views/admin/Region'),
    meta: { requiresAuth: true, title: i18n.t('regions') },
    children: [
      {
        path: '/admin/region/add',
        name: 'RegionAdd',
        component: () => import('@/views/admin/RegionAdd.vue'),
        meta: { requiresAuth: true, title: i18n.t('add-region') },
      },
    ]
  },
  {
    path: '/admin/tech-support',
    name: 'AdminTechSupport',
    component: () => import('@/views/admin/techSupport/index.vue'),
    meta: { requiresAuth: true, title: i18n.t('support-requests') },

  },
  {
    path: '/admin/tech-support/:id',
    name: 'AdminTechSupportId',
    component: () => import('@/views/admin/techSupport/TechSupportId.vue'),
    meta: { requiresAuth: true, title: i18n.t('support-requests') }
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import('@/views/user/index'),
    meta: { requiresAuth: true, title: i18n.t('edit-user-profile') },
    children: [
      {
        path: '/user/:id',
        name: 'UserId',
        component: () => import('@/views/user/UserContent.vue'),
        meta: { requiresAuth: true, title: i18n.t('edit-user-profile') },
      },
      {
        path: '/user/:id/admin-edit',
        name: 'UserId',
        component: () => import('@/views/user/UserPageEditAdmin.vue'),
        meta: { requiresAuth: true, title: i18n.t('edit-user-profile') },
      },
      {
        path: '/user/:id/confirmation-history',
        name: 'UserConfHistory',
        component: () => import('@/views/user/UserConfHistory.vue'),
        meta: { requiresAuth: true, title: i18n.t('confirmation-history-2') },
      },
    ]
  },
  {
    path: '/admin/about',
    name: 'AdminAbout',
    component: () => import('@/views/admin/about/index.vue'),
    meta: { requiresAuth: true, title: i18n.t('about-app') }
  },
  {
    path: '/admin/informer',
    name: 'AdminInformer',
    component: () => import('@/views/admin/informer/index.vue'),
    meta: { requiresAuth: true, title: i18n.t('calculation-of-damage-directories') },
    children: [
      {
        path: '/admin/informer/incident-types',
        name: 'IncidentTypes',
        component: () => import('@/views/admin/informer/IncidentTypes'),
        meta: { requiresAuth: true, title: i18n.t('incident-types') },
      },
      {
        path: '/admin/informer/subtypes',
        name: 'Subtypes',
        component: () => import('@/views/admin/informer/SubTypes'),
        meta: { requiresAuth: true, title: i18n.t('subtypes') },
      },
      {
        path: '/admin/informer/user-variables',
        name: 'UserVariables',
        component: () => import('@/views/admin/informer/UserVariables'),
        meta: { requiresAuth: true, title: i18n.t('custom-variables') },
      },
      {
        path: '/admin/informer/user-informer',
        name: 'UserInformer',
        component: () => import('@/views/admin/informer/UserInformer'),
        meta: { requiresAuth: true, title: i18n.t('directories') },
      },
      {
        path: '/admin/informer/taxes-coefficients',
        name: 'TaxesCoefficients',
        component: () => import('@/views/admin/informer/TaxesCoefficients'),
        meta: { requiresAuth: true, title: i18n.t('directories') },
      },
      {
        path: '/admin/informer/formulas',
        name: 'Formulas',
        component: () => import('@/views/admin/informer/Formulas'),
        meta: { requiresAuth: true, title: i18n.t('formulas') },
      },

    ]
  },
  {
    path: '/admin/region-informer',
    name: 'AdminRegionInformer',
    component: () => import('@/views/admin/regionInformer/index.vue'),
    meta: {requiresAuth: true, title: i18n.t('calculation-of-regions-rating-directories')},
    children: [
      {
        path: '/admin/region-informer/periods',
        name: 'Periods',
        component: () => import('@/views/admin/regionInformer/Periods.vue'),
        meta: { requiresAuth: true, title: i18n.t('periods') },
      },
      {
        path: '/admin/region-informer/regions',
        name: 'Regions',
        component: () => import('@/views/admin/regionInformer/Regions.vue'),
        meta: { requiresAuth: true, title: i18n.t('regions') },
      },
      {
        path: '/admin/region-informer/types',
        name: 'Types',
        component: () => import('@/views/admin/regionInformer/Types'),
        meta: { requiresAuth: true, title: i18n.t('type-2') },
      },
      {
        path: '/admin/region-informer/subtypes',
        name: 'Subtypes',
        component: () => import('@/views/admin/regionInformer/Subtypes.vue'),
        meta: { requiresAuth: true, title: i18n.t('subtype') },
      },
      {
        path: '/admin/region-informer/groups',
        name: 'Groups',
        component: () => import('@/views/admin/regionInformer/Groups.vue'),
        meta: { requiresAuth: true, title: i18n.t('group') },
      },
      {
        path: '/admin/region-informer/basic',
        name: 'Basic',
        component: () => import('@/views/admin/regionInformer/IndicatorBasic.vue'),
        meta: { requiresAuth: true, title: i18n.t('base') },
      },
      {
        path: '/admin/region-informer/final',
        name: 'Final',
        component: () => import('@/views/admin/regionInformer/IndicatorFinal.vue'),
        meta: { requiresAuth: true, title: i18n.t('totals') },
      },
      {
        path: '/admin/region-informer/algorithms',
        name: 'Algorithms',
        component: () => import('@/views/admin/regionInformer/Algorithms.vue'),
        meta: { requiresAuth: true, title: i18n.t('algorithms') },
      },
    ]
  },
  {
    path: '/region-indicators',
    name: 'ModeratorRegionIndicators',
    component: () => import('@/views/moderator/regionIndicator/index.vue'),
    meta: {requiresAuth: true, title: i18n.t('calculation-of-regions-rating-indicators-2')},
    children: [
      {
        path: '/region-indicators/filling-indicators',
        name: 'FillingIndicators',
        component: () => import('@/views/moderator/regionIndicator/FillingIndicators.vue'),
        meta: { requiresAuth: true, title: i18n.t('filling-in-the-indicators')},
      },
      {
        path: '/region-indicators/view-indicators',
        name: 'ViewIndicators',
        component: () => import('@/views/moderator/regionIndicator/ViewIndicators'),
        meta: {title: i18n.t('viewing-indicators')},
      }
    ]
  },
  {
    path: '/admin/about/:id',
    name: 'AdminAboutId',
    component: () => import('@/views/admin/about/AboutId.vue'),
    meta: { requiresAuth: true, title: i18n.t('about-app') },
  },
  {
    path: '/admin/change-log',
    name: 'ChangeLog',
    component: () => import('@/views/admin/ChangeLog.vue'),
    meta: { requiresAuth: true, title: i18n.t('change-log')}
  },
  {
    path: '/esg',
    name: 'Esg',
    component: () => import('@/views/esg/index.vue'),
    meta: { requiresAuth: true, title: i18n.t('sustainable-development-projects')},
    children: [
      {
        path: '/esg',
        name: 'index',
        component: () => import('@/components/template/esg/ContentEsg.vue'),
        meta: { requiresAuth: true, title: i18n.t('sustainable-development-projects')},
      },
    ]
  },
  {
    path: '/esg-guides',
    name: 'EsgGuides',
    component: () => import('@/views/esg/esgGuides/index.vue'),
    meta: { requiresAuth: true, title: i18n.t('sustainability-project-directories')},
    children: [
      {
        path: '/esg-guides/project-categories',
        name: 'ProjectCategories',
        component: () => import('@/views/esg/esgGuides/ProjectCategories.vue'),
        meta: { requiresAuth: true, title: i18n.t('sustainability-project-directories')},
      },
      {
        path: '/esg-guides/project-types',
        name: 'ProjectTypes',
        component: () => import('@/views/esg/esgGuides/ProjectTypes.vue'),
        meta: { requiresAuth: true, title: i18n.t('sustainability-project-directories')},
      },
      {
        path: '/esg-guides/project-subtypes',
        name: 'ProjectSubtypes',
        component: () => import('@/views/esg/esgGuides/ProjectSubtypes.vue'),
        meta: { requiresAuth: true, title: i18n.t('sustainability-project-directories')},
      },
    ]
  },
  {
    path: '/add-esg',
    name: 'AddEsg',
    component: () => import('@/components/template/esg/view-esg/ViewEsgBody.vue'),
    meta: { requiresAuth: true, title: i18n.t('add-esg')},
  },
  {
    path: '/esg/:id',
    name: 'EsgId',
    component: () => import('@/components/template/esg/view-esg/ViewEsgBody.vue'),
    meta: { requiresAuth: true, title: i18n.t('view-esg-requests')},
  },
  {
    path: '/event-management',
    name: 'EventManagement',
    component: () => import('@/views/event/event-management/index.vue'),
    meta: { title: i18n.t('manage-events') },
  },
  {
    path: '/add-event-management',
    name: 'AddEventManagement',
    component: () => import('@/views/event/event-management/AddEventManagement.vue'),
    meta: { requiresAuth: true, title: i18n.t('create-an-event') },
  },
  {
    path: '/event-management/:id',
    name: 'EventManagementId',
    component: () => import('@/views/event/event-management/EventManagementId.vue'),
    meta: { title: i18n.t('view-events') },
  },
  {
    path: '/edit-event-management/:id',
    name: 'EditEventManagement',
    component: () => import('@/views/event/event-management/EditEventManagement.vue'),
    meta: { requiresAuth: true, title: i18n.t('edit-event') },
  },
  {
    path: '/event',
    name: 'Event',
    component: () => import('@/views/event/index.vue'),
    meta: { title: i18n.t('events') },
  },
  {
    path: '/event-participants/:id',
    name: 'EditEventManagement',
    component: () => import('@/views/event/event-participants/index.vue'),
    meta: { requiresAuth: true, title: i18n.t('event-participants') },
    children: [
      {
        path: '/event-participants/:id',
        name: 'index',
        component: () => import('@/views/event/event-participants/EventParticipants.vue'),
        meta: { requiresAuth: true, title: i18n.t('event-participants') },
      },
      {
        path: '/event-participants/register/:id',
        name: 'EventParticipantsRegister',
        component: () => import('@/views/event/event-participants/EventParticipantsRegister.vue'),
        meta: { requiresAuth: true, title: i18n.t('register-event-participant') },
      },
    ]
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/Notifications.vue'),
    meta: { title: i18n.t('notifications') },
  },
  {
    path: '/rating-region',
    name: 'RatingRegion',
    component: () => import('@/views/RatingRegion.vue'),
    meta: { title: i18n.t('rating-of-regions')},
  },
  {
    path: '/delete-account',
    name: 'DeletedAccount',
    component: () => import('@/views/DeleteAccount.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'ecophone-front-panel';
  if (getCookie('authToken') === null && to.meta.requiresAuth){
    next({
      path: 'login',
      replace: true
    })
  }
  next()
})

export default router
