import {adminApi} from '@/api'

export default {
    state: {
        incidentTypeOptions: [
            { name: 'Загрязнение или уничтожение почв', value: '' },
            { name: 'Загрязнение водного объекта', value: '' },
            { name: 'Загрязнение воздуха', value: '' },
            { name: 'Рубка или уничтожение деревьев', value: '' },
            { name: 'Лесной или иной природный пожар', value: '' },
            { name: 'Уничтожение краснокнижных растений', value: '' },
            { name: 'Уничтожение животных или птиц', value: '' },
            { name: 'Уничтожение водных видов', value: '' },
        ],
        types: [],
        subtypes: [],
        specsTypes: [],
        deletedSpecsTypes: [],
        specsTypesKey: [],
        variablesValues: [],
        columns: [],
        formulas: [],
        formulasActions: [],
        userVariablesLoader: false,
        deletedFormula: []
    },
    getters: {
        getTypes: (state) => state.types,
        getSubtypes: (state) => state.subtypes,
        getSpecsTypes: (state) => state.specsTypes,
        getDeletedSpecsTypes: (state) => state.deletedSpecsTypes,
        getSpecsTypesKey: (state) => state.specsTypesKey,
        getVariablesValues: (state) => state.variablesValues,
        getColumns: (state) => state.columns,
        getUserVariablesLoader: (state) => state.userVariablesLoader,
        getFormulas: (state) => state.formulas,
        getDeletedFormula: (state) => state.deletedFormula,
        getFormulasActions: (state) => state.formulasActions
    },
    mutations: {
        setUserVariablesLoader(state, payload) {
            state.userVariablesLoader = payload
        },
        setTypes(state, payload) {
            state.types = payload
        },
        setSubtypes(state, payload) {
            state.subtypes = payload
        },
        setSpecsTypes(state, payload) {
            state.specsTypes = payload
            state.specsTypesKey = payload.filter(elem => elem.dataType === "Key")
        },
        setVariablesValues(state,payload) {
            state.variablesValues = payload
        },
        setColumns(state, payload) {
            state.columns = payload
        },
        setFormulas(state, payload) {
            state.formulas = payload
        },
        setFormulasActions(state, payload) {
            state.formulasActions = payload
        },
        showEditColumn(state, item) {
            state.columns = state.columns.map(val => {
                if (item.id === val.id) {
                    val.editColumn = true
                    val.editForm = item.name
                }

                return val
            })
        },
        closeEditColumn(state, item) {
            state.columns = state.columns.map(val => {
                if (item.id === val.id) {
                    val.editColumn = false
                }

                return val
            })
        },
        showEditColumnNumber(state, data) {
            state.columns = state.columns.map(val => {
                if (data.column.id === val.id) {
                    val.numbers.map(el => {
                        if (el.id === data.number.id) {
                            el.editColumnNum = true
                            el.numVal = el.amount.toString()
                            console.log(333)
                        }
                    })
                }

                return val
            })
        },
        closeEditColumnNumber(state, data) {
            state.columns = state.columns.map(val => {
                if (data.column.id === val.id) {
                    val.numbers.map(el => {
                        if (el.id === data.number.id) {
                            el.editColumnNum = false
                        }
                    })
                }

                return val
            })
        },
        deleteSpecsTypes(state, data) {
            state.deletedFormula = []
            if(state.deletedSpecsTypes.length) {
                state.deletedSpecsTypes.forEach(el => {
                    if (el.id === data.id) {
                        state.deletedSpecsTypes = []
                    }else if (el.id !== data.id){
                        state.deletedSpecsTypes = []
                        state.deletedSpecsTypes.push(data)
                    }
                })
            }else {
                state.deletedSpecsTypes.push(data)
            }
        },
        cleanDeletedSpecsTypes(state) {
            state.deletedSpecsTypes = []
        },
        deleteFormula(state, data) {
            state.deletedSpecsTypes = []
            if(state.deletedFormula.length) {
                state.deletedFormula.forEach(el => {
                    if (el.id === data.id) {
                        state.deletedFormula = []
                    }else if (el.id !== data.id){
                        state.deletedFormula = []
                        state.deletedFormula.push(data)
                    }
                })
            }else {
                state.deletedFormula.push(data)
            }
        },
        cleanDeletedFormula(state) {
            state.deletedFormula = []
        }
    },
    actions: {
        types({ commit }) {
            return new Promise((resolve, reject) => {
                adminApi.getTypes().then((res) => {
                    resolve(res.data.data)
                    commit('setTypes', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        subtypes({ commit }, id) {
            return new Promise((resolve, reject) => {
                adminApi.getSubtypes(id).then((res) => {
                    resolve(res.data.data)
                    commit('setSubtypes', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteType(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.deleteType(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editType(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.editType(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editSubtype(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.editSubtype(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        addSubtype(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.addSubtype(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        specsTypes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                adminApi.getSpecsTypes(payload).then((res) => {
                    resolve(res.data.data)
                    commit('setSpecsTypes', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        addVariable(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.addVariable(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteVariable(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.deleteVariable(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editVariable(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.editVariable(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        variableValues({commit}, payload) {
            return new Promise((resolve, reject) => {
                adminApi.getVariableValues(payload).then((res) => {
                    resolve(res.data)
                    commit('setVariablesValues', res.data.data);
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editVariableValues(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.editVariableValues(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        addVariableValues(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.addVariableValues(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteVariableValues(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.deleteVariableValues(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        columns({ commit }, payload) {
            return new Promise((resolve, reject) => {
                adminApi.getColumns(payload).then((res) => {
                    resolve(res.data.data)
                    commit('setColumns', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editColumns(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.editColumns(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteColumns(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.deleteColumns(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        addColumns(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.addColumns(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        addColumnNumber(state, payload) {
            return new Promise((resolve, reject) => {
                adminApi.addColumnNumber(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        formulas({ commit }, payload) {
            return new Promise((resolve, reject) => {
                adminApi.getFormulas(payload).then((res) => {
                    resolve(res.data.data)
                    commit('setFormulas', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        addFormulas(store, payload) {
            return new Promise((resolve, reject) => {
                adminApi.addFormulas(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteFormula(store, payload) {
            return new Promise((resolve, reject) => {
                adminApi.deleteFormula(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editFormula(store, payload) {
            return new Promise((resolve, reject) => {
                adminApi.editFormula(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        formulasActions({ commit }, payload) {
            return new Promise((resolve, reject) => {
                adminApi.getFormulasActions(payload).then((res) => {
                    console.log(res.data, 2222)
                    resolve(res.data.data)
                    commit('setFormulasActions', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        addFormulasAction(store, payload) {
            return new Promise((resolve, reject) => {
                adminApi.addFormulasAction(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteFormulasAction(store, payload) {
            return new Promise((resolve, reject) => {
                adminApi.deleteFormulasAction(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editFormulasAction(store, payload) {
            return new Promise((resolve, reject) => {
                adminApi.editFormulasAction(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        addFormulasElement(store, payload) {
            return new Promise((resolve, reject) => {
                adminApi.addFormulasElement(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editFormulasElement(store, payload) {
            return new Promise((resolve, reject) => {
                adminApi.editFormulasElement(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteFormulasElement(store, payload) {
            return new Promise((resolve, reject) => {
                adminApi.deleteFormulasElement(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}