import HTTP from '@/api/http'

const request = (payload, responseHandler, errorHandler) => {
    return HTTP.post('/support/request', payload).then(responseHandler).catch(errorHandler)
}
const requests = (payload, responseHandler, errorHandler) => {
    return HTTP.get('/support/requests', {
        params: {
            take: payload.take,
            skip: payload.skip,
        }
    }).then(responseHandler).catch(errorHandler)
}

export default {
    request,
    requests
}