import axios from 'axios'
import { getCookie } from "@/utils/cookie";

const HTTP = axios.create({
    baseURL: 'https://xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai' + '/api/',
})
HTTP.interceptors.request.use(function (config) {
    const apiToken = getCookie('authToken')
    config.headers = {
        Authorization: apiToken ? `Bearer ${apiToken}` : null,
        'Accept-Language': getCookie('locale')
    }
    return config;
});



export default HTTP
